<template>
    <div id="test">
         <!-- {{ getJson() }} -->
         <!-- <button @click="getJson">get</button>
          -->
         <!-- {{ jsi }} -->

         <div id="caminfo" class="row" v-for="cam in jsi" :key="cam.camid" >
             <div class="col">
                <div id="h1" class="d-flex  justify-content-between px-0 mx-0">
                    <h1>{{ cam.name }}</h1>
                    <button class="b-collapse" :class="[isToggled ? 'up' : 'down']" :title="[isToggled ? 'Скрыть' : 'Показать', ' подробное описание к камере']"  @click="isToggled = !isToggled" type="button" />
                </div>
                <div id="cammenu" class="d-flex  justify-content-between px-0 pb-2 mx-0">
                     <div id="views" v-if="cam.online==='1'">
                        Сейчас смотрят: {{ cam.views }}
                    </div>
                    <div id="views" class="offline" v-else>
                        Камера офлайн. Попробуйте открыть эту камеру позже.
                    </div>
                    
                </div>

                <div id="camDescriprion" class="collapse mx-1 my-0" :class="[isToggled ? 'show' : '']">
                            <p>{{ cam.description }}</p>
                </div>

             </div>
         </div>
    </div>
  
</template>

<script>
import axios from 'axios';
export default {
    name: "test",
    data(){
        return{
            jsonURL: "https://dvor.asteis.net/api/?camid="+this.camID,
            jsi: '',
            isToggled: false,
        };
    },
    props:{
        camID: {typeof: String, default: 'NaN'},
    },
    mounted(){
        let self = this;
        self.getJson();
        this.intervalid1 = setInterval(function(){
            if(self.jsi.length > 0) {
                self.getJson();
            }    
        },10000);
        
    },
    methods:{
        getJson() {          
            axios.get(this.jsonURL).then(response => (this.jsi = response.data)); 
            //alert(this.jsi);
            //return this.jsi;
           // this.jsi.push(jcamInfo);
            //document.title = "Смотреть видео с камеры на " + jsi['zak36a']['name'];
          
        },
        
        
    },
    beforeDestroy () {
       clearInterval(this.intervalid1)
    },
}
</script>
<style lang="scss">
 #h1{
        line-height: 1em;

        .b-collapse {
            display:block;
            height: 1em;
            width: 1em;
            border:none;
            background-color: #f5f5f5;
            
            background-size: 100% 100%;
            background-position: 0 0;
            background-repeat: no-repeat;
            font-size:calc(1em + .8vw);
            font-weight: 500;
            
        }
        .b-collapse.up {
                background-image:url(../assets/img/icons/chevron-up.svg);
            }
            .b-collapse.down{
                background-image:url(../assets/img/icons/chevron-down.svg);
            }

    }
.offline{
    color: rgba(250, 40, 80, 0.9);
}    
</style>