<template>
    <div id="footer" class="row justify-content-md-center gx-0 my-0 p py-2 border-top">
        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">

            <div class="d-flex justify-content-center">
                <div id="copyright" align="center" class="align-self-center ">&copy;&nbsp;{{ new Date().getFullYear() }} <a href="http://www.asteis.net">Астэйс-Телеком</a></div>
            </div>
        
        </div>
    </div>
</template>

<script>
export default {
  name: 'SiteFooter'
}   
</script>
<style lang="scss" scoped>
#footer {
    font-size:.85em;
    line-height: 1.7em;
}
    
</style>