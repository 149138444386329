<template>
  <div class="live">
    <CamPlayer :camID="id" />
    <CamList camNum="20" camListName="Камеры рядом" :camEX="id" />
    {{ jsi }}
  </div>
</template>

<script>
// @ is an alias to /src
import CamList from '@/components/CamList.vue'
import CamPlayer from '@/components/CamPlayer.vue';


export default {
  name: 'Live',
  created () {
            document.title = "Смотреть видео с камеры на "+this.$route.params.id;
        },
  components: {
    CamPlayer,
    CamList
  },
  props: {
    
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
}
</script>
