<template>
    <div id="NotFound">
        <div id="header" class="row gx-0 justify-content-center bg-white border-bottom">
          <div class="col-12 col-md-10 col-lg-8 col-xl-8 p-1">
            <div class="row gx-0">
                
                <div class="col">
                    <h1>Ошибка 404. Страница не найдена</h1>
                    <p>
                        Страница с адресом {{ $route.fullPath }} не найдена на сервере.
                    </p>
                    <div class="">

                        
                        <li><button type="button" class="btn btn-link" @click="$router.push('/')">Вернуься на предыдущую страницу</button></li>
                        <li><button type="button" class="btn btn-link" @click="$router.go(-1)">Перейти на Главную</button></li>

                    </div>
                </div>
                <div class="col">
                   <img src="@/assets/img/dvor404.png" class=" d-inline-block my-2 mx-2">
                </div>
            </div>

            
           
            
          </div>
        </div>
    </div>
</template>