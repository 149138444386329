import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Live from '../views/Live.vue'
import PathNotFound from '../views/NotFound.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bookmarks',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/live',
    name: 'Live Cams',
    component: Live,
    redirect: '/'
  },
  {
    path : '/live/:id',
    name: 'Live',
    component: Live,
    
  },
  { 
    path: '/404', name: '404', component: PathNotFound,
  },
  {
     path: '/:pathMatch(.*)*', component: PathNotFound,
  }

  
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})




export default router
