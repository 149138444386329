<template>
    <div id="camList" class="row gx-0 justify-content-center mybg-gray">
        <div class="col-12 col-md-10 col-lg-8 col-xl-8" v-for="cam in list" :key="cam.camid">
            <div class="row gx-0">
                <div class="col px-2">
                    <h2 class="mt-2" v-show="camListName">{{ camListName }}</h2>
                </div>
            </div>
            
            <div class="row gx-0 py-1">
                <div v-for="curcam in cam" :key="curcam" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 p-1">
                    <router-link :to="'/live/'+curcam.camid" >
                    <div class="row gx-0"> 
                        <div class="col-12">
                            <div class="row gx-0 cardimg bg-dark">
                                <!-- <div class="col"> -->
                                <div class="image" >
                                    <img class="lazyload" ref="camimg" data-sizes="auto" :data-src="'//dvor.asteis.net/hls/' + curcam.camid + '/preview.jpg'" @error="defaultPic" @mouseover="setGif" @mouseleave="setDefault" :id="curcam.camid" style="width:100%"  :alt="curcam.description">
                                </div>
                            </div>
                            <div class="row gx-0 cardinfo">
                                <div class="col px-2 py-1">
                                    <h3  :title="curcam.name">{{ curcam.name }}</h3>

                                    <div v-if="curcam.online==='1'" class="p-0 m-0">
                                        <span>
                                            Зрителей: <span>{{ kilo(curcam.views) }}</span>
                                         
                                        </span>                          
                                    </div>
                                    <div v-else>
                                        <span class="offline">Камера офлайн</span>
                                    </div>
                        

                                </div>
                            </div>
                                
                                
                        </div>
                    </div>
                    </router-link>
                </div>
            
            </div>
        </div>
    




    </div>
</template>
<script>
import axios from 'axios';
//import axios from 'axios';
//import LazyLoadDirective from "@/directives/lazyImages";
import lazysizes from 'lazysizes';

// Vue.filter('formatNumber', (value) => {
//   ending=['k','m','b','t']
//   if(n.length<4){
//     return n;
//   }else{
//     return `${n[0]}${n[1]!='0'?`.${n[1]}`:''}${ending[Math.floor((n.length-1)/3)-1]}`;
//   }
// })


const kilo2 = n => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + " тыс.";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + " млн.";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + " млр.";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + " тлн.";
};

export default {
    name: 'CamList',

    props: {
        //camID: {typeof: String, default: 'NaN'},
        //camView: {typeof:String, default: '500'},
        camNum: {typeof:String, default: '500'},
        camListName: {typeof:String, default: ''},
        camEX: {typeof:String, default: ''},
    },
    data(){
        return {
            list: null,
            
        };
    },
    computed: {
    // a computed getter
    reversedMessage: function () {
      // `this` points to the vm instance
      //return curcam.views.split('').reverse().join('')
      //return (curcam) => this.itemById(curcam).description;
    }
  },
    mounted() {
        axios
            .get('https://dvor.asteis.net/api/?ex='+this.camEX)
            .then(response => (this.list = response.data));

        
    },
    methods: {
        defaultPic(valid){
            valid.target.src="https://dvor.asteis.net/img/dvorcam_poster.jpg";
            valid.target.ref="err";
            //alert(valid.target.ref);
         },
         hover(valid){
             valid.target.class="bg-white";

         },
         click(valid){
             
         },
         setGif(valid){
            if(valid.target.ref!=="err" ){
                if(valid.target.src!=="//dvor.asteis.net/hls/"+valid.target.id+"/preview.gif"){
                    valid.target.src="//dvor.asteis.net/hls/"+valid.target.id+"/preview.gif";
                    //alert(valid.target.id);
                }   
            }

         },
         setDefault(valid){
             if(valid.target.ref!=="err"){
                 if(valid.target.src!=="//dvor.asteis.net/hls/"+valid.target.id+"/preview.jpg"){
                    //valid.target.src=valid.target.src.replace('gif', 'jpg');
                    valid.target.src="//dvor.asteis.net/hls/"+valid.target.id+"/preview.jpg";
                }
             }
         },
        kilo(n){
            return kilo2(n);
        }
         
    }
}
</script>



<style lang="scss" scoped>
#camList{
    a {
        color: black;
        text-decoration: none;

        :hover {
                background: #fff;
            }
           
        
        :active  {
                background: #f0f0f0;
            }
    } 
    .lazyload,
    .lazyloading {
            opacity: 0;
           
            min-height: 200px;
        }
        .lazyloaded {
            opacity: 1;
            transition: opacity 300ms;
        }
    // div.cardimg {
    //     ::after{
    //         display: block;
    //         position: relative;
    //         content:"";
    //         background-image: url(../assets/img/icons/play-fill.svg);
    //         background-size: 100% 100%;
    //         background-repeat: no-repeat;
    //         background-position: 0 0;
    //         background-color: none;
    //         filter: invert(1) opacity(0.4);
    //         height: 50%;
    //         width: 50%;
    //         top:-75%;
    //         left: 25%;
    //         visibility: hidden;
        
    //         :hover {
    //             visibility: visible;
    //         }
    //     }
    // }
    div.cardinfo{
        span {
            display: inline-block;
            font-size: 1em;
            

            img {
                display: inline-block;
                height: 1em;
            }
        }
        .offline {
            color: rgba(250,40,80,.9);
        }
    }
}
    
    
</style>