<template>
    <div id="camPlayer" class="row mybg-gray pt-2 justify-content-center gx-0 border-bottom " >
        <div class="col-12 col-md-10 col-lg-8 px-1" >
            <div id="camPlayerVideo" class="row gx-0">
                <div class="col">
                    <video id="video"  ref="video" style="width:100%"  controls preload="auto" :poster="'//dvor.asteis.net/hls/' + camID + '/preview.jpg'"></video>
                </div>
            </div>
           
            

            <div id="camPlayerVideoInfo" class="row gx-0" v-for="cam in caminfo" :key="cam.camid">
                <div class="col text-left px-1">
                    <!-- <h1 class="d-flex justify-content-between px-0 mx-0">
                        <span>{{ cam.name }}</span>
                        <button class="b-collapse" :class="[isToggled ? 'up' : 'down']" :title="[isToggled ? 'Скрыть' : 'Показать', ' подробное описание к камере']"  @click="isToggled = !isToggled" type="button" />
                        
                        
                    </h1>
                    <div id="camPlayerVideoMenu" class="d-flex flex-row justify-content-between gx-0">
                        <div class="">Сейчас смотрят: {{ cam.views }}</div>
                        <div id="subButton" class="">
                            <a id="share">
                                <span class="d-none d-sm-inline-block">Поделиться</span>
                            </a>
                        </div>

                    </div>
            
                    <div id="camDescriprion" class="collapse m-2" :class="[isToggled ? 'show' : '']">
                        <div class="">
                            <p>{{ cam.description }}</p>
                        </div>
                    </div> -->
                    <jtest :camID="camID"></jtest>
                    
                </div>
                
            </div>
            
        </div>
        <!-- <div v-else class="col-12 col-md-10 col-lg-8 px-1">
            <div class="row gx-0">
                <div class="col">
                    <h1 >Запрошенная камера не существует!</h1>
                </div>
            </div>
            
        </div> -->
   <!-- <div v-for="data in $options.myJson"> {{ data.kc29k1 }} </div> -->
        
    <!---->
    </div>
</template>
 
<script>
import jtest from '@/components/test.vue';
import Hls from 'hls.js';
//import Camjson from '@/cam.json';
import axios from 'axios';
//import axios from 'axios';



export default {
    name: 'CamPlayer',
   // myJson: Camjson,
    components:{
        jtest
    },
    props: {
        camID: {typeof: String, default: 'NaN'},
        camView: {typeof:String, default: '1'},
        
    },
    data(){
        return {
            caminfo: '',
            jsonURL: "https://dvor.asteis.net/api/?camid="+this.camID,
            isToggled: false,

        };
    },
    methods:{

      
    },
    mounted() {
        axios
            .get(this.jsonURL)
            .then(response => (this.caminfo = response.data));

      //alert(this.jsonURL);

        if (Hls.isSupported()) {
        let hls = new Hls(); //this.videoSrc; //
        //let stream =  "https://cdn.jwplayer.com/manifests/pZxWPRg4.m3u8";
        let stream =  "https://dvor.asteis.net/hls/"+ this.camID +"/stream.m3u8";
        let video = this.$refs["video"];       
        hls.loadSource(stream);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
        });
        }
        else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = stream;
            video.addEventListener('canplay', function () {
            video.play();
            });
        }

    
    },
    
    
}
</script>

<style lang="scss" scoped>



video{
    background-image: url(https://dvor.asteis.net/img/dvorcam_poster.jpg);
    background-color:black;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
#camPlayerVideoInfo{
    h1{
        line-height: 1em;

        .b-collapse {
            display:block;
            height: 1em;
            width: 1em;
            border:none;
            background-color: #f5f5f5;
            
            background-size: 100% 100%;
            background-position: 0 0;
            background-repeat: no-repeat;
            
        }
        .b-collapse.up {
                background-image:url(../assets/img/icons/chevron-up.svg);
            }
            .b-collapse.down{
                background-image:url(../assets/img/icons/chevron-down.svg);
            }

    }

    #camPlayerVideoMenu{

        #subButton{

            a{
                margin-left: 1em;
                text-transform: uppercase;
                font-weight: 600;
                cursor: pointer;
                padding-left: 1.5em;

                background-size: 1em 1em;
                background-position: 0 0.05em;
                background-repeat: no-repeat;


            }
            a#share {
                background-image: url(//dvor.asteis.net/img/share-fill.svg);
            }
            a#bookmark {
                background-image: url(//dvor.asteis.net/img/bookmark-fill.svg);
            }

        }

    }
    
}
</style>