<template>
<div id="body" class="container-fluid gx-0 my-0" @readystatechange="setTitle">
        <div id="header" class="row gx-0 justify-content-center bg-white border-bottom">
          <div class="col-12 col-md-10 col-lg-8 col-xl-8 p-1">
              
            <div id="nav" class="">  
              <router-link to="/">
                <img src="@/assets/img/dvorcam.svg" height="36" alt="" class="mr-4">
              </router-link>
              
              <!-- <router-link to="/bookmarks">Избранное</router-link>
              <router-link to="/live/zak36a">Live</router-link> -->
            </div>

          </div>
        </div>
      <!-- <router-view/> -->
      <!-- {{ $route }} -->
      <!-- <router-view :key="$route.fullPath" /> -->
      <router-view :key="$route.fullPath" />
      <!-- <component :is="layout">
        <router-view/>
      </component> -->

    <SiteFooter />
  </div>
</template>
<script>
import SiteFooter from '@/components/SiteFooter.vue'
export default {
  name: 'app',
  components:{
    SiteFooter
  },
  created () {
    document.title = "Астэйс Двор";
  },
  methods: {
    intlFormat(num)
        {
          return new Intl.NumberFormat().format(Math.round(num*10)/10);
        },
    makeFriendly(num)
        {
        if(num >= 1000000)
            return intlFormat(num/1000000)+'млн.';
        if(num >= 1000)
            return intlFormat(num/1000)+'тыс';
        return intlFormat(num);
        },
  },
  // computed: {
  //   layout() {
  //     const layoutName = this.$roure.meta.layout || 'CamList';
  //     return () => import(`@/views/${layoutName}.vue`);
  //   }
  //}
    
}


</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
</style>

<style lang="scss">
$white:		#ffffff;
$black:		#000000;
$blue:		#0089ff;
$blue2:		#0f8fff;
$gray0:		#f5f5f5;
$gray:		#f9f9f9;
$red:		#f41224;
$violet:	#4149F2;
$lightblue:	#1ca9fb;
$midblue:	#1476fb;
.mybg-gray{
  background-color: $gray;
}

*, ::before, ::after  {
    box-sizing: border-box;
}

#app {
  font-family: Roboto, "Open-sans" , Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 0px;
  
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}
body {

  h1,h2,h3,h4,#h1{
    font-weight: 500;
  }
  h1, #h1 h1 {
    font-size:calc(1em + .8vw);
  }
  h2 {
    font-size:calc(1em + .5vw);
  }
  h3 {
    font-size:calc(1em + .2vw);
  }
  h4 {
    font-size:calc(1em + .2vw);
  }
}

</style>
